import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useLanguage } from "../context/LanguageContext";
import { translate } from "../utils/translation";

function ServicesPage() {
  const { language } = useLanguage() || { language: "en" }; // Fallback to "en" if language is undefined

  return (
    <Layout>
      <SEO
        keywords={[
          `web development services`,
          `full stack developer`,
          `Giuseppe Gangi`,
          `Barcelona`,
        ]}
        title={translate("services.title", language)}
      />

      <section className="max-w-6xl mx-auto px-4 py-12">
        <h1 className="text-4xl mb-10 font-extrabold text-center text-gray-800">
          {translate("services.heading", language)}
        </h1>
        <p className="text-lg mb-10 text-center text-gray-600">
          {translate("services.introduction", language)}
        </p>

        {/* Full-Stack Development Section */}
        <div className="mb-16">
          <h2 className="text-3xl mb-6 font-bold text-gray-800">
            {translate("services.full_stack_heading", language)}
          </h2>
          <p className="text-lg mb-6 text-gray-600">
            {translate("services.full_stack_description", language)}
          </p>
          <ul className="list-disc list-inside text-lg mb-6 text-gray-600">
            <li>{translate("services.full_stack_custom_web", language)}</li>
            <li>
              {translate("services.full_stack_responsive_design", language)}
            </li>
            <li>
              {translate("services.full_stack_api_integration", language)}
            </li>
            <li>{translate("services.full_stack_ecommerce", language)}</li>
          </ul>
          <div className="text-center">
            <a
              href="/contact"
              className="inline-block w-full md:w-auto text-center px-8 py-3 text-white bg-blue-600 rounded-full hover:bg-blue-700 text-lg font-bold transition duration-300 ease-in-out"
            >
              {translate("services.full_stack_cta", language)}
            </a>
          </div>
        </div>

        {/* AI Services Section */}
        <div className="mb-16">
          <h2 className="text-3xl mb-6 font-bold text-gray-800">
            {translate("services.ai_heading", language)}
          </h2>
          <p className="text-lg mb-6 text-gray-600">
            {translate("services.ai_description", language)}
          </p>
          <ul className="list-disc list-inside text-lg mb-6 text-gray-600">
            <li>{translate("services.ai_custom_solutions", language)}</li>
            <li>{translate("services.ai_automation", language)}</li>
            <li>{translate("services.ai_data_analysis", language)}</li>
          </ul>
          <div className="text-center">
            <a
              href="/contact"
              className="inline-block w-full md:w-auto text-center px-8 py-3 text-white bg-blue-600 rounded-full hover:bg-blue-700 text-lg font-bold transition duration-300 ease-in-out"
            >
              {translate("services.ai_cta", language)}
            </a>
          </div>
        </div>

        {/* SmartTV Development Section */}
        <div className="mb-16">
          <h2 className="text-3xl mb-6 font-bold text-gray-800">
            {translate("services.smarttv_heading", language)}
          </h2>
          <p className="text-lg mb-6 text-gray-600">
            {translate("services.smarttv_description", language)}
          </p>
          <ul className="list-disc list-inside text-lg mb-6 text-gray-600">
            <li>
              {translate("services.smarttv_custom_development", language)}
            </li>
            <li>{translate("services.smarttv_drm_integration", language)}</li>
            <li>{translate("services.smarttv_compatibility", language)}</li>
            <li>{translate("services.smarttv_interfaces", language)}</li>
          </ul>
          <div className="text-center">
            <a
              href="/contact"
              className="inline-block w-full md:w-auto text-center px-8 py-3 text-white bg-blue-600 rounded-full hover:bg-blue-700 text-lg font-bold transition duration-300 ease-in-out"
            >
              {translate("services.smarttv_cta", language)}
            </a>
          </div>
        </div>

        {/* Performance Optimization Section */}
        <div className="mb-16">
          <h2 className="text-3xl mb-6 font-bold text-gray-800">
            {translate("services.optimization_heading", language)}
          </h2>
          <p className="text-lg mb-6 text-gray-600">
            {translate("services.optimization_description", language)}
          </p>
          <ul className="list-disc list-inside text-lg mb-6 text-gray-600">
            <li>{translate("services.optimization_speed", language)}</li>
            <li>{translate("services.optimization_scalability", language)}</li>
            <li>{translate("services.optimization_experience", language)}</li>
            <li>{translate("services.optimization_seo", language)}</li>
          </ul>
          <div className="text-center">
            <a
              href="/contact"
              className="inline-block w-full md:w-auto text-center px-8 py-3 text-white bg-blue-600 rounded-full hover:bg-blue-700 text-lg font-bold transition duration-300 ease-in-out"
            >
              {translate("services.optimization_cta", language)}
            </a>
          </div>
        </div>

        {/* Consulting Services Section */}
        <div className="mb-16">
          <h2 className="text-3xl mb-6 font-bold text-gray-800">
            {translate("services.consulting_heading", language)}
          </h2>
          <p className="text-lg mb-6 text-gray-600">
            {translate("services.consulting_description", language)}
          </p>
          <ul className="list-disc list-inside text-lg mb-6 text-gray-600">
            <li>{translate("services.consulting_strategy", language)}</li>
            <li>{translate("services.consulting_process", language)}</li>
            <li>{translate("services.consulting_management", language)}</li>
            <li>{translate("services.consulting_mentorship", language)}</li>
          </ul>
          <div className="text-center">
            <a
              href="/contact"
              className="inline-block w-full md:w-auto text-center px-8 py-3 text-white bg-blue-600 rounded-full hover:bg-blue-700 text-lg font-bold transition duration-300 ease-in-out"
            >
              {translate("services.consulting_cta", language)}
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ServicesPage;
